import axios from "axios";
// const BASE_URL = "http://10.60.62.186:8000/webreactapp";
// const BASE_URL = "http://10.60.62.169:8000/webreactapp";
// const BASE_URL = "https://swasthyadevapi.kavinsoft.in/webreactapp";
// const BASE_URL = "https://swasthyatestapi.kavinsoft.in/webreactapp";
// const BASE_URL = "https://swasthya.kctestapi.kavinsoft.in/webreactapp";
const BASE_URL = "https://misapi.swaasthyacirclefoundation.com/webreactapp";


const api = {
  login: async (userid, password, additionaldata) => {
    try {
      const response = await axios.post(`${BASE_URL}/web_login`,{
          userid,
          password,
          ...additionaldata
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error("Login error:", error.message);
      throw error;
    }
  },

  uploadApk: async (data) => {
    try {
        const response = await axios.post(`${BASE_URL}/admin_mobile_apk_upload`, data, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });

        return response.data;
    } catch (error) {
        console.error('Upload error:', error.message);
        throw error;
    }
  },

  get_RawData_Report: async (requestData) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/admin_raw_data_download`,
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching raw data:", error.message);
      throw error;
    }
  },
};

export default api;
