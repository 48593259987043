import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import React, { useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import api from "../api";

const MobileUpload = () => {
  const toast = useRef(null);
  const fileUploadRef = useRef(null);

  const [loading, setLoading] = useState(false);

  const currentDate = new Date();
  const formattedCurrentDate = `${currentDate
    .getDate()
    .toString()
    .padStart(2, "0")}-${(currentDate.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${currentDate.getFullYear()} ${currentDate
    .getHours()
    .toString()
    .padStart(2, "0")}:${currentDate
    .getMinutes()
    .toString()
    .padStart(2, "0")}:${currentDate.getSeconds().toString().padStart(2, "0")}`;

  const formik = useFormik({
    initialValues: {
      fld_mob_version_name: "",
      fld_mob_version_code: "",
      remarks: "",
      apk: null,
    },
    validationSchema: Yup.object({
      fld_mob_version_name: Yup.string().required(
        "Mobile Version Name is required"
      ),
      fld_mob_version_code: Yup.string().required(
        "Mobile Version Code is required"
      ),
      remarks: Yup.string().required("Remark is required"),
      apk: Yup.mixed().required("APK file is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        setLoading(true);

        const formData = new FormData();
        formData.append("fld_mob_version_name", values.fld_mob_version_name);
        formData.append("fld_mob_version_code", values.fld_mob_version_code);
        formData.append("remarks", values.remarks);
        formData.append("apk", values.apk);

        const response = await api.uploadApk(formData);

        if (response && response.status === "1") {
          toast.current.show({
            severity: "success",
            summary: "Upload Successful",
            detail: response.responsemessage || "APK uploaded successfully.",
          });
          resetForm();
          if (fileUploadRef.current) {
            fileUploadRef.current.clear();
          }
        } else {
          throw new Error(
            response
              ? response.responsemessage ||
                "An error occurred while uploading the APK."
              : "No response from the server."
          );
        }
      } catch (error) {
        console.error("Upload error: ", error);
        toast.current.show({
          severity: "error",
          summary: "Upload Failed",
          detail:
            error.message || "An unexpected error occurred. Please try again.",
        });
      } finally {
        setLoading(false);
      }
    },
  });

  const handleFileUpload = (event) => {
    formik.setFieldValue("apk", event.files[0]);
  };

  return (
    <div className="m-5">
      {loading && (
        <div className="pro-spin">
          <ProgressSpinner
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        </div>
      )}
      <h1 className="heading">Mobile APK Upload</h1>
      <Toast ref={toast} position="top-right" />

      <form onSubmit={formik.handleSubmit} className="m-8 ">
        <div className="grid flex align-items-center mt-3 gap-8">
          <div className="col-2 p-0">
            <div className="formslabel">Mobile Version Name :</div>
          </div>
          <div className="col p-0 flex justify-content-start">
            <InputText
              id="fld_mob_version_name"
              name="fld_mob_version_name"
              placeholder="Enter Mobile Version Name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.fld_mob_version_name}
              style={{ width: "80%" }}
              className={
                formik.errors.fld_mob_version_name &&
                formik.touched.fld_mob_version_name
                  ? "p-invalid p-inputtext"
                  : "p-inputtext"
              }
            />
          </div>
        </div>
        <div className="grid flex align-items-center mt-1 gap-8">
          <div className="col-2 p-0"></div>
          <div className="col p-0 flex justify-content-start">
            {formik.errors.fld_mob_version_name &&
              formik.touched.fld_mob_version_name && (
                <span className="p-error">
                  {formik.errors.fld_mob_version_name}
                </span>
              )}
          </div>
        </div>

        <div className="grid flex align-items-center mt-3 gap-8">
          <div className="col-2 p-0">
            <div className="formslabel">Mobile Version Code :</div>
          </div>
          <div className="col p-0 flex justify-content-start">
            <InputText
              id="fld_mob_version_code"
              name="fld_mob_version_code"
              placeholder="Enter Mobile Version Code"
              onChange={formik.handleChange}
              keyfilter="int"
              onBlur={formik.handleBlur}
              style={{ width: "80%" }}
              value={formik.values.fld_mob_version_code}
              className={
                formik.errors.fld_mob_version_code &&
                formik.touched.fld_mob_version_code
                  ? "p-invalid p-inputtext "
                  : "p-inputtext"
              }
            />
          </div>
        </div>
        <div className="grid flex align-items-center mt-1 gap-8">
          <div className="col-2 p-0"></div>
          <div className="col p-0 flex justify-content-start">
            {formik.errors.fld_mob_version_code &&
              formik.touched.fld_mob_version_code && (
                <span className="p-error">
                  {formik.errors.fld_mob_version_code}
                </span>
              )}
          </div>
        </div>

        <div className="grid flex align-items-center mt-3 gap-8">
          <div className="col-2 p-0">
            <div className="formslabel">Remarks :</div>
          </div>
          <div className="col p-0 flex justify-content-start">
            <InputTextarea
              id="remarks"
              name="remarks"
              placeholder="Remarks"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.remarks}
              style={{ width: "80%" }}
              className={
                formik.errors.remarks && formik.touched.remarks
                  ? "p-invalid p-inputtext "
                  : "p-inputtext"
              }
            />
          </div>
        </div>
        <div className="grid flex align-items-center mt-1 gap-8">
          <div className="col-2 p-0"></div>
          <div className="col p-0 flex justify-content-start">
            {formik.errors.remarks && formik.touched.remarks && (
              <span className="p-error">{formik.errors.remarks}</span>
            )}
          </div>
        </div>

        <div className="grid flex align-items-center mt-3 gap-8">
          <div className="col-2 p-0">
            <div className="formslabel">APK File :</div>
          </div>
          <div className="col p-0 flex justify-content-start">
            <FileUpload
              name="apk"
              accept=".apk"
              customUpload
              uploadHandler={handleFileUpload}
              auto
              style={{ width: "80%" }}
              ref={fileUploadRef}
            />
          </div>
        </div>
        <div className="grid flex align-items-center mt-1 gap-8">
          <div className="col-2 p-0"></div>
          <div className="col p-0 flex justify-content-start">
            {formik.errors.apk && formik.touched.apk && (
              <span className="p-error">{formik.errors.apk}</span>
            )}
          </div>
        </div>
        <div className="grid flex align-items-center mt-5 gap-8">
          <div className="col-2 p-0"></div>
          <div className="col p-0 flex justify-content-start">
            <Button type="submit" className="formbutton">
              Submit
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default MobileUpload;
