import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { v4 as uuidv4 } from "uuid";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { ProgressSpinner } from "primereact/progressspinner";
import api from "../api";
import ForgotPass from "./ForgotPass";
import { Dialog } from "primereact/dialog";

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const sessionID = uuidv4();
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toast = useRef(null);
  const formik = useFormik({
    initialValues: {
      userid: "",
      password: "",
    },
    validationSchema: Yup.object({
      userid: Yup.string().required("User name is required"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const additionaldata = {
          synceddatetime: "2023-01-24 11:57:34",
          FormCode: "201",
          ApiKey: "kavin",
          AppTypeNo: "3",
          AppVersion: "1.0.0",
          DbVersion: "10.4.1",
          DbSource: "W",
        };
        const response = await api.login(
          values.userid,
          values.password,
          additionaldata
        );
        // console.log("res", response);
        if (response.status === "1") {
          sessionStorage.setItem("sessionId", sessionID);
          sessionStorage.setItem(
            "RoleCode",
            response.loged_in_details[0].fld_role_id
          );
          sessionStorage.setItem(
            "userid",
            response.loged_in_details[0].fld_user_id
          );
          navigate("/main/dashboard");
        } else {
          toast.current.show({
            severity: "error",
            summary: "Login Failed",
            detail: response.message,
          });
        }
      } catch (error) {
        console.error("Error:", error);
        toast.current.show({
          severity: "error",
          summary: "Login Failed",
          detail: "An unexpected error occurred. Please try again.",
        });
      } finally {
        setLoading(false);
      }
    },
  });

  const [visible, setVisible] = useState(false);

  const onHideDialog = () => {
    setVisible(false);
  };

  return (
    <div className="body">
      <Toast ref={toast} position="top-right" />

      <Dialog
        header="Forgot Password"
        visible={visible}
        style={{ width: "30%" }}
        onHide={() => setVisible(false)}
      >
        <ForgotPass onHide={onHideDialog} toast={toast} />
      </Dialog>

      {loading && (
        <div className="pro-spin">
          <ProgressSpinner
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        </div>
      )}
      <div>
        <div className="grid log-grid">
          {windowWidth >= 900 && (
            <div className="col bg-login">
              <img
                src="/Swaasthya-Geoplus.svg"
                alt="background"
                className="Login-img"
              />
            </div>
          )}
          <div className="vertical-divider"></div>
          <div className="col log-grid ">
            <form onSubmit={formik.handleSubmit} style={{ minWidth: "60%" }}>
              <div>
                {/* <h2 style={{color:"#134B93"}}>Welcome</h2> */}
                <div className="flex">
                  <h2 className="login-h2" style={{color:"#134B93"}}>Welcome to</h2>
                  <h2
                    className="ml-2 login-h2"
                    style={{ fontWeight: "500", marginTop: "-15px" ,color:"#68B6E1"}}
                  >
                    Swaasthya Geoplus
                  </h2>
                </div>

                <div className="mt-2">
                  <label htmlFor="userid" style={{color:"#134B93"}}>User ID</label>
                  <div className="mt-2">
                    <InputText
                      style={{ width: "100%", borderRadius: "15px" }}
                      id="userid"
                      name="userid"
                      placeholder="Enter User ID"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.userid}
                      className={
                        formik.errors.userid && formik.touched.userid
                          ? " p-inputtext"
                          : "p-inputtext"
                      }
                    />
                  </div>

                  {formik.errors.userid && formik.touched.userid && (
                    <span className="p-error">{formik.errors.userid}</span>
                  )}
                </div>

                <div
                  className={`mt-2 ${
                    formik.touched.password && formik.errors.password
                      ? "p-invalid"
                      : ""
                  }`}
                >
                  <label htmlFor="password" style={{color:"#134B93"}}> Password</label>
                  <div className="mt-2">
                    <Password
                      id="password"
                      name="password"
                      style={{ width: "100%" }}
                      placeholder="Enter Password"
                      toggleMask
                      feedback={false}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                      className={
                        formik.errors.password && formik.touched.password
                          ? "p-invalid p-password loginPage"
                          : "p-password loginPage"
                      }
                    />
                  </div>

                  {formik.errors.password && formik.touched.password && (
                    <span className="p-error">{formik.errors.password}</span>
                  )}
                </div>
                <br />
              </div>
              <div>
                <Button className="login-btn" type="submit" label="Login" />
              </div>
            </form>
            <div
              style={{
                position: "fixed",
                bottom: "10px",
                fontSize: "14px",
              }}
            >
              <span
                style={{
                  color: "#9D9D9D",
                  fontWeight: "500",
                  fontSize: "12px",
                }}
              >
                Powered by KavinTech Corporation, Bangalore
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
