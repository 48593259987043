import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import api from "../api";
import { ProgressSpinner } from "primereact/progressspinner";


const ForgotPass = ({onHide, toast }) => {
  const [loading, setLoading] = useState(false);


    const formik = useFormik({
        initialValues: {
          userName: "",
          password: "",
          confirmPassword: "",
        },
        validationSchema: Yup.object({
          userName: Yup.string().required("User name is required"),
          password: Yup.string().required("Password is required"),
          confirmPassword: Yup.string().required("Confirm Password is required"),

        }),
        onSubmit: async (values) => {
    
          try {
            setLoading(true);
            const response = await api.ForgotPassword(
              values.userName,
              values.password,
              values.confirmPassword,
            );
            console.log("res", response);
            if (response.status === "1") {
                  onHide()

                toast.current.show({
                    severity: "success",
                    summary: "Password Changed",
                    detail: response.message ,
                  });
            } else {
              toast.current.show({
                severity: "error",
                summary: "Password Changed Failed",
                detail: response.message ,
              });
            }
          } catch (error) {
            console.error("Error:", error);
            toast.current.show({
              severity: "error",
              summary: "Password Changed Failed",
              detail: "An unexpected error occurred. Please try again.",
            });
          } finally {
            setLoading(false);
          }
        },
      });
    
  return (
    <div>
      {/* <Toast ref={toast} position="top-right" /> */}

      {loading && (
        <div className="pro-spin">
          <ProgressSpinner
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        </div>
      )}

        <form onSubmit={formik.handleSubmit} style={{ minWidth: "60%" }}>
              <div>

                <div className="mt-2">
                  <label htmlFor="userName" style={{fontSize:"14px"}}>User Name</label>
                  <div className="mt-2">
                    <InputText
                      style={{ width: "100%", borderRadius: "15px", height:"40px", fontSize:"14px" }}
                      id="userName"
                      name="userName"
                      placeholder="Enter User Name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.userName}
                      className={
                        formik.errors.userName && formik.touched.userName
                          ? " p-inputtext"
                          : "p-inputtext"
                      }/>
                  </div>

                  {formik.errors.userName && formik.touched.userName && (
                    <span className="p-error">{formik.errors.userName}</span>
                  )}
                </div>
                <br/>
                <div>
                  <label htmlFor="password" style={{fontSize:"14px"}}> New Password</label>
                  <div className="mt-2">
                    <Password
                      id="password"
                      name="password"
                      style={{ width: "100%" }}
                      placeholder="Enter Password"
                      toggleMask
                    //   feedback={false}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                      className={
                        formik.errors.password && formik.touched.password
                          ? "p-invalid p-password forgotPass"
                          : "p-password forgotPass"
                      }
                    />
                  </div>

                  {formik.errors.password && formik.touched.password && (
                    <span className="p-error">{formik.errors.password}</span>
                  )}
                </div>
                <br />

                <div>
                  <label htmlFor="password" style={{fontSize:"14px"}}> Confirm Password</label>
                  <div className="mt-2">
                    <Password
                      id="confirmPassword"
                      name="confirmPassword"
                      style={{ width: "100%" }}
                      placeholder="Enter Confirm Password"
                      toggleMask
                      feedback={false}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}

                      value={formik.values.confirmPassword}
                      className={
                        formik.errors.confirmPassword && formik.touched.confirmPassword
                          ? "p-invalid p-password forgotPass"
                          : "p-password forgotPass"
                      }
                    />
                  </div>

                  {formik.errors.confirmPassword && formik.touched.confirmPassword && (
                    <span className="p-error">{formik.errors.confirmPassword}</span>
                  )}
                </div>

              </div>
              <div className="flex justify-content-around mt-5">
                <Button className="forgot-cancel-btn" severity="danger" outlined type="button" label="Cancel" onClick={onHide}/>
                <Button className="forgot-btn" type="submit" label="Update Password" />

              </div>
            </form>
    </div>
  )
}

export default ForgotPass