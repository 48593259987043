import React, { useRef, useState, useEffect } from "react";
import { Menu } from "primereact/menu";
import { TieredMenu } from "primereact/tieredmenu";
import { Button } from "primereact/button";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import Dashboard from "../Pages/Dashboard";
import { Toast } from "primereact/toast";
import MobileUpload from "../Pages/MobileUpload";
import Reports from "../Pages/Reports";

const Main = () => {
  const menu = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("");
  const toast = useRef(null);
  const fldRoleCode = sessionStorage.getItem("RoleCode");
  // console.log("roll code", fldRoleCode);

  useEffect(() => {
    setActiveTab(location.pathname);
  }, [location.pathname]);
  const PageNotFound = () => {
    return (
      <>
        <h1 style={{textAlign:"center"}}>404 Page not found</h1>
        <p  style={{textAlign:"center"}}>The page you are looking for does not exist.</p>
      </>
    );
  };
  const displayContent = () => {
    return (
      // <Routes>
      //   <Route exact path="/dashboard" element={<Dashboard />} />
      //   <Route exact path="/mobileUpload" element={<MobileUpload/>}/>
      //   <Route exact path="/reports" element={<Reports/>}/>

      // </Routes>
      <Routes>
        {fldRoleCode === "3" && (
          <>
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/mobileUpload" element={<MobileUpload />} />
            <Route exact path="/reports" element={<Reports />} />
          </>
        )}
        {fldRoleCode === "1" && (
          <>
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/reports" element={<Reports />} />
          </>
        )}
        <Route exact path="/*" element={<PageNotFound />} />
      </Routes>
    );
  };

  const items1 = [
    {
      label: "Log out",
      icon: "pi pi-sign-out",
      command: () => {
        sessionStorage.clear();
        navigate("/");
      },
    },
  ];

  const menuItems = [
    {
      label: (
        <p
          className={`m-0 pt-1 ${
            activeTab === "/main/dashboard" ? "active-text" : ""
          }`}
        >
          Dashboard
        </p>
      ),
      icon:
        activeTab === "/main/dashboard" ? (
          <i
            className="pi pi-home"
            style={{ fontSize: "22px", color: "#68B6E1", paddingRight: "10px" }}
          />
        ) : (
          <i
            className="pi pi-home"
            style={{ fontSize: "22px", paddingRight: "10px" }}
          />
        ),
      className: activeTab === "/main/dashboard" ? "active-tab" : "",
      command: () => {
        setActiveTab("/main/dashboard");
        navigate("/main/dashboard");
      },
    },
    // {
    //   label: (
    //     <p
    //       className={`m-0 ${
    //         activeTab === "/main/mobileUpload" ? "active-text" : ""
    //       }`}
    //     >
    //       Mobile apk upload
    //     </p>
    //   ),
    //   icon:
    //     activeTab === "/main/mobileUpload" ? (
    //       <i
    //         className="pi pi-file-arrow-up"
    //         style={{ fontSize: "22px", color: "#66A5AD", paddingRight: "10px" }}
    //       />
    //     ) : (
    //       <i
    //         className="pi pi-file-arrow-up"
    //         style={{ fontSize: "22px", paddingRight: "10px" }}
    //       />
    //     ),
    //   className: activeTab === "/main/mobileUpload" ? "active-tab" : "",
    //   command: () => {
    //     setActiveTab("/main/mobileUpload");
    //     navigate("/main/mobileUpload");
    //   },
    // },
    {
      label: (
        <p
          className={`m-0 ${
            activeTab === "/main/reports" ? "active-text" : ""
          }`}
        >
          Reports
        </p>
      ),
      icon:
        activeTab === "/main/reports" ? (
          <i
            className="pi pi-file"
            style={{ fontSize: "22px", color: "#68B6E1", paddingRight: "10px" }}
          />
        ) : (
          <i
            className="pi pi-file"
            style={{ fontSize: "22px", paddingRight: "10px" }}
          />
        ),
      className: activeTab === "/main/reports" ? "active-tab" : "",
      command: () => {
        setActiveTab("/main/reports");
        navigate("/main/reports");
      },
    },
  ];

  const superMenuItems = [
    {
      label: (
        <p
          className={`m-0 pt-1 ${
            activeTab === "/main/dashboard" ? "active-text" : ""
          }`}
        >
          Dashboard
        </p>
      ),
      icon:
        activeTab === "/main/dashboard" ? (
          <i
            className="pi pi-home"
            style={{ fontSize: "22px", color: "#68B6E1", paddingRight: "10px" }}
          />
        ) : (
          <i
            className="pi pi-home"
            style={{ fontSize: "22px", paddingRight: "10px" }}
          />
        ),
      className: activeTab === "/main/dashboard" ? "active-tab" : "",
      command: () => {
        setActiveTab("/main/dashboard");
        navigate("/main/dashboard");
      },
    },
    {
      label: (
        <p
          className={`m-0 ${
            activeTab === "/main/mobileUpload" ? "active-text" : ""
          }`}
        >
          Mobile apk upload
        </p>
      ),
      icon:
        activeTab === "/main/mobileUpload" ? (
          <i
            className="pi pi-file-arrow-up"
            style={{ fontSize: "22px", color: "#68B6E1", paddingRight: "10px" }}
          />
        ) : (
          <i
            className="pi pi-file-arrow-up"
            style={{ fontSize: "22px", paddingRight: "10px" }}
          />
        ),
      className: activeTab === "/main/mobileUpload" ? "active-tab" : "",
      command: () => {
        setActiveTab("/main/mobileUpload");
        navigate("/main/mobileUpload");
      },
    },
    {
      label: (
        <p
          className={`m-0 ${
            activeTab === "/main/reports" ? "active-text" : ""
          }`}
        >
          Reports
        </p>
      ),
      icon:
        activeTab === "/main/reports" ? (
          <i
            className="pi pi-file"
            style={{ fontSize: "22px", color: "#68B6E1", paddingRight: "10px" }}
          />
        ) : (
          <i
            className="pi pi-file"
            style={{ fontSize: "22px", paddingRight: "10px" }}
          />
        ),
      className: activeTab === "/main/reports" ? "active-tab" : "",
      command: () => {
        setActiveTab("/main/reports");
        navigate("/main/reports");
      },
    },
  ];
  return (
    <>
      <div className="sidebar">
        <Toast ref={toast} position="top-right" />

        <div className="flex justify-content-center mt-5">
          <img src="/Swaasthya-Geoplus.svg" alt="logo" style={{ width: "120px" }} />
        </div>
        {fldRoleCode === "3" && (
          <>
            <div className="menu-container">
              <Menu model={superMenuItems} className="sidebarMenu mt-5 pl-4" />
            </div>
            <div
              className="sidebartieredmenu-container"
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "15px",
              }}
            >
              <TieredMenu model={items1} popup ref={menu} />
              <Button
                className="sidebartieredmenu pi pi-user"
                onClick={(e) => menu.current.toggle(e)}
              >
                <span className="menuFont">Super Admin</span>{" "}
                <i className="pi pi-chevron-up" />
              </Button>
            </div>
          </>
        )}
        {fldRoleCode === "1" && (
          <>
            <div className="menu-container">
              <Menu model={menuItems} className="sidebarMenu mt-5 pl-4" />
            </div>
            <div
              className="sidebartieredmenu-container"
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "15px",
              }}
            >
              <TieredMenu model={items1} popup ref={menu} />
              <Button
                className="sidebartieredmenu pi pi-user"
                onClick={(e) => menu.current.toggle(e)}
              >
                <span className="menuFont">Administrator</span>{" "}
                <i className="pi pi-chevron-up" />
              </Button>
            </div>
          </>
        )}
      </div>
      <div className="cardStyle">
        <div>{displayContent()}</div>
      </div>
    </>
  );
};
export default Main;
